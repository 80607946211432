import React from "react";
import { Box, Text, Heading, Flex } from "@chakra-ui/react";
import Layout from "../components/Layout";
import {ArrowBackIcon} from '@chakra-ui/icons'
import LocalLink from "../components/GatsbyLink";

const Legal = () => {
  return (
    <Layout showTitle showFooter={false}>
      <Flex justify="center">
        <Box p={4} width="4xl">
          <Box mb={6}>
            <LocalLink to="/plugin"><ArrowBackIcon /> Back to Plugin</LocalLink>
          </Box>
          <Heading as="h1" size="lg" mb={2}>
            arXiv Xplorer Privacy Policy
          </Heading>
          <Text fontSize="sm" mb={2}>
            Last Updated Date: November 2, 2023
          </Text>
          <Box borderWidth={1} borderRadius="md" p={4} mb={4}>
            <Text fontSize="lg" mb={2}>
              Welcome to arXiv Xplorer. By using this website and/or the ChatGPT
              plugin, you agree to this Privacy Policy.
            </Text>
            <Text fontSize="lg" mb={2}>
              <strong>Acknowledgment:</strong> Thank you to arXiv for use of its
              open access interoperability. arXiv Xplorer is not affiliated with
              arXiv.org and makes no claim over any paper content.
            </Text>
            <Text fontSize="lg" mb={2}>
              <strong>No Data Collection:</strong> We don't collect or store any
              personal or user-specific data.
            </Text>
            <Text fontSize="lg" mb={2}>
              <strong>Prohibited Activities:</strong> Any form of system abuse,
              or unauthorized access is strictly prohibited.
            </Text>
            <Text fontSize="lg" mb={2}>
              The software is provided "as is", without warranty of any kind, express
              or implied, including but not limited to the warranties of merchantability,
              fitness for a particular purpose and noninfringement. In no event shall the
              authors or copyright holders be liable for any claim, damages or other
              liability, whether in an action of contract, tort or otherwise, arising
              from, out of or in connection with the software or the use or other dealings in the software.
            </Text>
            <Text fontSize="lg" mb={2}>
              <strong>Policy Updates:</strong> This policy may be updated from
              time to time; changes will be posted here.
            </Text>
            <Text fontSize="lg" mb={2}>
              <strong>Contact:</strong> For questions, contact us at:
              legal@arxivxplorer.com
            </Text>
            <Text fontSize="lg">
              If you disagree with this policy, please don't use arXiv Xplorer.
            </Text>
          </Box>
        </Box>
      </Flex>
    </Layout>
  );
};

export default Legal;
